<template>
    <div class="wrap-container sn-container">
        <div class="sn-content">
            <div class="sn-title"></div>
            <div class="sn-body">
                <div class="wrap-container">
                    <div class="table">
                        <a-carousel arrows autoplay :dot-position="dotPosition" :dots="false" :autoplaySpeed="10000">
                            <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow"style="left: 10px;zIndex: 1">
                                <a-icon type="left-circle" />
                            </div>
                            <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                                <a-icon type="right-circle" />
                            </div>
                            <div style="float:left;margin-right:24px;color: white;height: 2rem" v-for="(item, index) in listData" :key="index">
                                <a-avatar icon="user" :size="64"/>
                                <p class="avatar-text"  style="margin-top: -0.7rem;">{{ item.title }}</p><br>
                                <p class="avatar-text">获奖经历：</p><br>
                                <p class="avatar-text">{{ item.date }}</p><br>
                            </div>
                        </a-carousel>
                        <!--<vue-seamless-scroll :data="listData" class="seamless-warp" :class-option="optionSetting">
                            <div style="float:left;margin-right:24px;color: white;height: 2rem" v-for="(item, index) in listData" :key="index">
                                <a-avatar icon="user" :size="64"/>
                                <p class="avatar-text"  style="margin-top: -0.7rem;">{{ item.title }}</p><br>
                                <p class="avatar-text">获奖经历：</p><br>
                                <p class="avatar-text">{{ item.date }}</p><br>
                            </div>
                        </vue-seamless-scroll>-->
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
    name: 'good-students',
    components: {
        vueSeamlessScroll
    },
    data() {
        return {
            dotPosition: 'right',
            listData: [{
                title: '龙枝锋  2020春报读  汽车运用与维修技术专业  专科',
                date: '2020年国开奖学金'
            }, {
                title: '王杰  2020春报读 汽车运用与维修技术专业 专科',
                date: '2020年国开奖学金'
            }, {
                title: '裴钰莹  2019秋报读 工商企业管理专业 专科',
                date: '2020年国开奖学金'
            }, {
                title: '顾衍君  2020春报读 眼视光技术专业 专科',
                date: '2020年国开奖学金'
            }, {
                title: '陈永辉  2020春报读 行政管理专业 专科',
                date: '2020年国开奖学金'
            }]
        }
    },
    computed: {
        optionSetting () {
            return {
                step: 0.2, // 数值越大速度滚动越快
                limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                // autoPlay: false,
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 149, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 10000 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .sn-container {
        left: 1370px;
        top: 110px;
        %table-style {
            width: 100%;
            height: 35px;
            table-layout: fixed;
            tr {
                td {
                    padding: 10px 5px;
                    text-align: center;
                    background-color: transparent;
                    white-space: nowrap;
                    overflow: hidden;
                    color: #E2E5FF;
                    font-size: 14px;
                }
            }
        }
        .table {
            .table-header {
                @extend %table-style;
            }
            .seamless-warp {
                height: 2rem;
                overflow: hidden;
                visibility: visible;
                .colorRed {
                    color: #FF4669;
                }
                .colorOrange {
                    color: #FFC956;
                }
                .item {
                    height: auto;
                    @extend %table-style;
                    tr {
                        td {
                            &.title {
                                text-overflow: ellipsis;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
    .avatar-text {
        font-size: 16px;
        margin-left: 1rem;
    }
</style>