<template>
    <div class="business-container">
        <div class="chart" id="chart_left1"></div>
    </div>
</template>

<script>
import api from '@/api'

export default {

    name: 'thesis-info',
    data() {
        return {}
    },
    mounted() {
        this.getEchartLeft1();
    },
    methods: {
      async getEchartLeft1() {
            let myChart = echarts.init(document.getElementById('chart_left1'));
            /*let charts = {
                xData: [5000, 2000, 1000],
                yData: ['需提交数', '已提交数', '已评审数']
            }
            let xValue = charts.xData;
            let yValue = charts.yData;
            */
            let xValue = [];
            let yValue = [];
            let res = await api.bigdata.getGraduatThesisInfo({organizationId: localStorage.getItem('organizationId')})
            if (res.code === 200) {
                xValue = [res.data.needCommitNum, res.data.commitNum, res.data.auditNum];
                yValue = ['需提交数', '已提交数', '已评审数'];
            }
            let color = ['rgba(14,109,236', 'rgba(255,91,6', 'rgba(100,255,249'];

            let lineY = [];
            for (let i = 0; i < xValue.length; i++) {
                let x = i
                if (x > color.length - 1) {
                    x = color.length - 1
                }
                let data = {
                    name: yValue[i],
                    color: color[x] + ')',
                    value: xValue[i],
                    itemStyle: {
                        normal: {
                            show: true,
                            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                offset: 0,
                                color: color[x] + ', 0.3)'
                            }, {
                                offset: 1,
                                color: color[x] + ', 1)'
                            }], false),
                            barBorderRadius: 10
                        },
                        emphasis: {
                            shadowBlur: 15,
                            shadowColor: 'rgba(0, 0, 0, 0.1)'
                        }
                    }
                }
                lineY.push(data)
            }
            // 指定配置和数据
            let option = {
                color: color,
                tooltip: {
                    trigger: 'item',
                },
                grid: {
                    borderWidth: 0,
                    top: '5%',
                    left: '2%',
                    right: '2%',
                    bottom: '0%',
                    containLabel: true
                },
                xAxis: [{
                    type: 'value',
                    axisTick: { // 坐标轴 刻度
                        show: true
                    },
                    axisLine: { // 坐标轴 轴线
                        show: true
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: { // 坐标轴标签
                        show: true, // 是否显示
                        interval:0,//代表显示所有x轴标签显示
                        inside: false,  // 是否朝内
                        textStyle: { // 样式
                            color: '#b3ccf8',
                            fontSize: 13
                        },
                    }
                }],
                yAxis: [{
                    type: 'category',
                    inverse: true,
                    axisTick: {
                        show: true
                    },
                    axisLine: {
                        show: true
                    },
                    axisLabel: {
                        show: true,
                        inside: false,
                        textStyle: {
                            color: '#b3ccf8',
                            fontSize: 13
                        },
                    },
                    data: yValue
                }],
                series: [{
                    name: '',
                    type: 'bar',
                    zlevel: 2,
                    barWidth: '10px',
                    data: lineY,
                    animationDuration: 1500,
                    label: {
                        normal: {
                            color: '#b3ccf8',
                            show: false,
                            position: [0, '-15px'],
                            textStyle: {
                                fontSize: 13
                            },
                            formatter: (a, b) => {
                                return a.name;
                            }
                        }
                    }
                }]
            };

            // 把配置给实例对象
            myChart.setOption(option, true);
            window.addEventListener('resize', () => {
                myChart.resize();
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .business-container {
        .chart {
            height: 3rem;
        }
    }
</style>