<template>
    <div class="brand-container">
        <div class="wrap">
            <header>
                <div class="weather" />
                <h2>毕业监测</h2>
                <div class="showTime" />
            </header>

            <section class="mainbox">
                <div class="item left">
                    <div class="panel">
                        <h2>毕业论文进行情况</h2>
                        <thesis-info />
                        <div class="panel-footer"></div>
                    </div>
                    <div class="panel-left2">
                        <h2>社会实践进行情况</h2>
                        <social-practice-info />
                        <div class="panel-footer"></div>
                    </div>
                </div>

                <div class="item center">
                    <div class="resume">
                        <div class="resume-hd">
                            <ul>
                                <li>
                                    <countTo :startVal='startVal' :endVal='4527' :duration='6000' separator=""></countTo>
                                </li>
                                <li>
                                    <countTo :startVal='startVal' :endVal='satisfyGraduatNum' :duration='6000' separator=""></countTo>
                                </li>
                            </ul>
                        </div>
                        <div class="resume-bd">
                            <ul>
                                <li>本学期提交毕业申请人数</li>
                                <li>已满足毕业条件人数</li>
                            </ul>
                        </div>
                    </div>

                    <div class="panel-live">
                        <div class="resume" style="width: 7rem;margin-left: -1rem;margin-top: 0.5rem">
                            <div class="resume-hd" >
                                <h2>优秀学生</h2>
                                <good-students />
                            </div>
                        </div>

                        <div class="resume" style="width: 7rem;margin-left: -1rem;margin-top: 0.5rem">
                            <div class="resume-hd" >
                                <h2>毕业班学分获得情况</h2>
                                <credit-info />
                            </div>
                        </div>

                        <div class="panel-footer"></div>
                    </div>
                </div>

                <div class="item right">
                    <div class="panel">
                        <h2>毕业资料完善情况</h2>
                        <graduat-data-info />
                        <div class="panel-footer"></div>
                    </div>

                    <div class="panel-dynamic">
                        <h2>实时动态</h2>
                        <time-dynamic />
                        <div class="panel-footer"></div>
                    </div>
                </div>
            </section>

        </div>

    </div>
</template>

<script>
import '@/assets/js/flexible'
import countTo from 'vue-count-to'
import ThesisInfo from "./c/ThesisInfo.vue";
import SocialPracticeInfo from "./c/SocialPracticeInfo.vue";
import GraduatDataInfo from "./c/GraduatDataInfo.vue";
import TimeDynamic from "./c/TimeDynamic.vue";
import GoodStudents from "./c/GoodStudents.vue";
import CreditInfo from "./c/CreditInfo.vue";
import api from "@/api"

export default {
    components: {
        CreditInfo,
        GoodStudents,
        TimeDynamic,
        GraduatDataInfo,
        SocialPracticeInfo,
        ThesisInfo,
        countTo},
    name: 'graduat-monitor',
    data() {
        return {
            startVal: 0,
            satisfyGraduatNum: 0,
        }
    },
    created() {
        this.initOrganizationByDomain();
    },
    mounted() {
        this.getGraduatCount();
    },
    methods: {
        async getGraduatCount() {
            let res = await api.bigdata.getGraduatCount({organizationId: localStorage.getItem('organizationId')})
            if (res.code === 200) {
                this.satisfyGraduatNum = res.data.satisfyGraduatNum;
            }
        },
        async initOrganizationByDomain() {
            const organizationId = localStorage.getItem('organizationId')
            if (!organizationId) {
                const domain =  window.location.origin || 100000
                let res = await api.protal.getOrganizationByDomain({ domain })
                if (res.code === 200 && res.data) {
                    localStorage.setItem('organizationId', res.data.organizationId)
                    location.reload();
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../assets/css/bigdata-screen/today-dynamic.scss";
</style>